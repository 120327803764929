export const state = {
  selected_user_id: null,
  user: null,
};

export const mutations = {
  SET_USER_ID(state, user_id) {
    state.selected_user_id = user_id;
  },
  SET_USER(state, user) {
    state.user = user;
  },
};

export const actions = {
  selectUserID({ commit }, user_id) {
    commit("SET_USER_ID", user_id);
  },
  selectUser({ commit }, user) {
    commit("SET_USER", user);
  },
};
