export const state = {
  programSelected: null,
};

export const mutations = {
  SELECT_PROGRAM(state, program) {
    state.programSelected = program;
  },
};

export const getters = {
  selectedProgram(state) {
    return state.programSelected;
  },
};

export const actions = {
  selectProgram({ commit }, program) {
    commit("SELECT_PROGRAM", program);
  },
};
