import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import VueYoutube from "vue-youtube";

import vco from "v-click-outside";

import router from "./router";
import store from "@/state/store";
import i18n from "./i18n";

import "@/assets/scss/app.scss";
import excel from "vue-excel-export";

import "@/plugins/vue-formulate";
import "@/plugins/api-gen";
import "@/plugins/moment";
import "@/plugins/vue-video-player";

import Embed from "v-video-embed";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

Vue.component("pulse-loader", PulseLoader);
Vue.config.productionTip = false;

Vue.use(VueYoutube);
Vue.use(BootstrapVue);
Vue.use(vco);
Vue.use(Embed);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(excel);
Vue.use(require("vue-chartist"));
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places",
  },
  installComponents: true,
});
Vue.component("apexchart", VueApexCharts);

Vue.filter("truncate", function (data, num) {
  const reqdString = data.split("").slice(0, num).join("");
  return reqdString;
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
