import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";
import "@/assets/scss/plugins/vueformulate.scss";

import toggablePassword from "@/components/toggablePassword";

import multiSelect from "@/components/formulate/multiSelect";

Vue.component("ToggablePassword", toggablePassword);
Vue.component("MultiSelect", multiSelect);

Vue.use(VueFormulate, {
  library: {
    "toggable-password": {
      classification: "text",
      component: "toggablePassword",
    },
    "multi-select": {
      classification: "select",
      component: "MultiSelect",
    },
  },
});
